@use '../../style.scss' as global;

$color-text-secondary-700: #344054;
$datepicker__border-radius: 12px;

.datepickerPopup {
  z-index: 9999;
}

.datepicker :global,
.datepickerPopup :global {
  position: relative;
  display: inline-flex;
  /* pop up container */
  .react-datepicker-popper {
    margin: 0;
    padding: 0;

    /* calendar container */
    .react-datepicker,
    .DatePicker-datepickerCal {
      margin: 0;
      padding: 0;
      border: 1px solid global.$color-gray-200;
      border-radius: $datepicker__border-radius;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      margin-top: 0;
      font-weight: 500;
      font-family: "Outfit";
      font-size: 16px;
      line-height: 24px;
      color: global.$color-dark-blue-new;
    }

    /* header container */
    .react-datepicker__header,
    .react-datepicker__header--custom {
      background-color: global.$color-white;
      border-bottom-width: 0;
      padding: 8px 0 0 0;
      border-top-left-radius: $datepicker__border-radius;
      border-top-right-radius: $datepicker__border-radius;

      /* title for time picker select */
      &--time {
        padding-bottom: 16px;
        padding-left: 12px;
        padding-right: 12px;
        text-align: left;
        border-bottom: 1px solid global.$color-gray-200;

        &:not(&--only) {
          border-top-left-radius: $datepicker__border-radius;
          border-top-right-radius: $datepicker__border-radius;
        }
      }
    }

    /* header icon button */
    .DatePicker-calendarHeaderNavButton {
      border-radius: $datepicker__border-radius;
      height: 20px;
      width: 20px;
      margin: 8px 0 8px 0;
    }

    /* header container for week day: Mo, Tu, We etc */
    .react-datepicker__day-names {
      margin: 0;
      padding: 0 24px 0 24px;
    }

    /* header week day item: Mo, Tu, We etc */
    .react-datepicker__day-names .react-datepicker__day-name {
      font-weight: 500;
      color: $color-text-secondary-700;
      margin: 0;
      padding: 10px 8px;
      font-family: "Outfit"; // in Figma "Inter"
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      width: 24px;
    }

    /* calendar content container */
    .react-datepicker__month-container {
      width: 100%;
      margin: 0;
      margin-bottom: 4px;
      background-color: global.$color-white;
      border-radius: $datepicker__border-radius;

      .react-datepicker__month {
        margin: 0;
        padding-bottom: 0;
      }
    }

    /* content container for day: 1, 2, 3 etc */
    .react-datepicker__week {
      display: flex;
      padding: 0 24px 0 24px;
      height: 40px;
      margin-top: 4px;
    }

    /* day container: 1 */
    .react-datepicker__day {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin: 0;
      font-family: "Outfit"; // in Figma "Inter"
      font-size: 14px;
      font-weight: 500;
      color: $color-text-secondary-700;
      &:hover {
        border-radius: 50%;
      }

      &--outside-month {
        color: #667085;
      }

      &--today {
        position: relative;
        &::after {
          content: '•';
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 14px; // Adjust the size of the dot
        }
      }

      &--keyboard-selected {
        background-color: global.$color-white;
        color: $color-text-secondary-700;
      }

      &--selected {
        background-color: global.$color-gray-blue;
        border-radius: 50%;
        color: global.$color-white;
      }

      &--disabled {
        opacity: 0.5;
      }
    }

    /* today button */
    .react-datepicker__today-button {
      background: global.$color-white;
      border-top: 1px solid global.$color-gray-200;
      text-align: left;
      cursor: pointer;
      padding: 0;
      clear: left;
    }

    /* time select container */
    .react-datepicker__time-container {
      position: block;
      display: relative;
      padding: 4px 0 4px 0;
      border-top: 1px solid global.$color-gray-200;
      width: 328px;
      border-radius: $datepicker__border-radius;

      &--with-today-button {
        position: relative;
        display: inline;
        border: none;
        border-top: 1px solid global.$color-gray-200;
        border-radius: $datepicker__border-radius;
        right: 0;
        top: 0;
      }

      .react-datepicker__time {
        position: relative;
        background: white;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;

        .react-datepicker__time-box {
          width: 100%;
          overflow-x: hidden;
          margin: 0 auto;
          text-align: center;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;

          ul.react-datepicker__time-list {
            list-style: none;
            margin: 0;
            overflow-y: scroll;
            padding-right: 0;
            padding-left: 0;
            width: 100%;
            box-sizing: content-box;

            li.react-datepicker__time-list-item {
              height: 34px;
              padding: 10px 16px !important;
              white-space: nowrap;
              font-weight: 400;
              font-family: "Outfit";
              font-size: 14px;
              font-weight: 400;
              color: global.$color-gray-950;
              text-align: left;

              &:hover {
                cursor: pointer;
                color: global.$color-gray-700;
                background-color: global.$color-white;
              }

              &--selected {
                background-color: global.$color-gray-blue;
                color: white;
                font-weight: 400;

                &:hover {
                  background-color: global.$color-gray-blue;
                  color: white;
                }
              }

              &--disabled {
                display: none;

                &:hover {
                  cursor: default;
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }

    /* children container (bottom buttons container) */
    .react-datepicker__children-container {
      margin: 0;
      padding: 0;
    }
  }
}

/* custom header */
.datepickerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 0 24px 0 24px !important;
}

/* time caption of custom header */
.datepickerHeaderTimeCaption {
  padding-bottom: 16px;
  padding-left: 12px;
  padding-right: 12px;
  text-align: left;
  border-bottom: 1px solid global.$color-gray-200;
  margin-top: 5px;
  margin-bottom: 8px;
  font-weight: 500;
  font-family: "Outfit";
  font-size: 16px;
  line-height: 24px;
  color: global.$color-dark-blue-new;
}

/* header custom chevron icons container */
.calendarHeaderNav {
  display: flex;
  gap: 24px;
}

/* header custom chevron icon button */
.calendarHeaderNavButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $datepicker__border-radius;
  border: none;
  pointer-events: auto;
  cursor: pointer;
  background-color: global.$color-white;
  &:hover {
    opacity: 0.5;;
  }
}

/* header custom chevron icon */
.calendarHeaderNavIcon {
  pointer-events: auto;
  height: 20px;
  width: 20px;
}

// month/year toggle button - October 2024
.viewToggleBtn {
  font-size: 16px !important;
  font-weight: 500 !important;
  margin-right: 2px !important;
  color: $color-text-secondary-700 !important;
  &:disabled {
    color: $color-text-secondary-700 !important;
  }
}

.bottomButtonsContainer {
  display: flex;
  gap: 8px;
  height: 44px;
  width: 296px;
  padding: 16px;
  border-top: 1px solid global.$color-gray-200;

}

.bottomButton {
  width: 144px !important;
}

.todayButtonContent {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;

  &Selected {
    background-color: global.$color-gray-50;
  }
}

.todayCalendarIcon {
  height: 20px;
  width: 20px;
}

.timeHeaderContainer {
  display: flex;
  justify-content: stretch;
  width: 316px;
  padding: 6px;
  border-top: 1px solid global.$color-gray-200;
}

.timeHeaderContainerInner {
  display: flex;
  flex-grow: 1;
  justify-content: stretch;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
}

.errorBorder {
  border: 1px solid #FDA29B;
  border-radius: 8px;
}

.timeContainer {
  display: flex;
  flex-grow: 1;
  justify-content: stretch;
}

.alertCircleIcon {
  height: 16px;
  width: 16px;
}

.timeHeaderText {
  color: global.$color-gray-700;
  line-height: 20px;
}
