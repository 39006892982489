@use './PatientDashboardCommon.scss';
@use '../../style.scss' as global;

.mainContainer {
  position: relative;
  background: global.$color-white;
}

.mainContainer,
.mainContainer * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.patientDetailSection {
  @extend .columnContainer;
  margin-top: global.$spacing-xl;
}

.avatarDeviceContainer {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: global.$spacing-small;
  justify-content: space-between;
  margin: global.$spacing-medium 0;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 30px;
  border: 0.75px solid global.$color-border-gray;
  background-color: global.$color-dark-blue-100;
  white-space: nowrap;
}

.avatarAndText {
  @extend .columnContainer;
  justify-content: center;
  gap: global.$spacing-xs;
}

.personInitials {
  color: global.$color-gray-blue;
}

.patientPresenceTopContainer {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: global.$spacing-small;
  max-width: 400px;
  min-width: 300px;
  padding: global.$spacing-xs;
  background: global.$color-gray-50;
  border-radius: 16px;
  margin-top: -40px;
}

.deviceIconContainer {
  padding: global.$spacing-small;
  background: global.$color-gray-100;
  border-radius: 100px;
}

.presenceContainer {
  @extend .columnContainer;
  justify-content: center;
  gap: 0;
}

.actionsContainer {
  display: flex;
  align-items: center;
  gap: global.$spacing-small;
}

.addButton {
  padding: global.$spacing-xs global.$spacing-small !important;
  line-height: 20px !important;
  text-transform: none !important;
}

.dropDownButton {
  //border width compensation
  padding: 7px 11px !important;
}

.addIcon {
  color: global.$color-white;
}

.redText {
  color: global.$color-red-600;
}

.overviewTextContainer {
  margin-top: global.$spacing-xl;
  margin-bottom: global.$spacing-large;
}

.cardsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 1440px;
  gap: global.$spacing-large;
  margin: 0 auto global.$spacing-xl;
}

.sleepOptionsContainer {
  @extend .columnContainer;
  align-items: stretch;
  gap: global.$spacing-large;
}

.HorizontalTabsButton {
  font-size: 16px !important;
  line-height: 24px !important;
}
