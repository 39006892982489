@use '../../../style.scss' as global;

.button {
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border: 1px solid global.$color-gray-200 !important;
  border-radius: 8px !important;
  padding: 0 !important;
}

.button:hover {
  background-color: #F5F7F9;
}

