@use '../../style.scss' as global;

.topContainer {
  border: 1px solid global.$color-gray-200;
  border-radius: 10px;
  margin-top: global.$spacing-2xs;
}

.selectContainer {
  height: 44px;
}
