@use '../../UserDetails';

.backdrop {
  z-index: 2147483647 !important;
}

.gridLine {
  margin-bottom: 15px !important;
}

.contentInput {
  width: 400px;
}
