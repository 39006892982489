@use '../../PatientDashboardCommon.scss';
@use '../../../../style.scss' as global;

$chartHeight: 466px;

.noDataChartContainer {
  height: $chartHeight;
  margin-top: global.$spacing-large;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: global.$spacing-xl;
  background-color: global.$color-gray-50;
  border-radius: 16px;
  border: 1px solid global.$color-gray-100;
}

.chartContainer {
  height: $chartHeight;
  margin-top: global.$spacing-medium;
}
