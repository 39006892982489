@use '../../PatientDashboardCommon.scss';
@use '../../../../style.scss' as global;

.rowContainer {
  padding: global.$spacing-small 0;
}

.rowLine {
  border-bottom: 1px solid global.$color-gray-100;
}

.valueContainer {
  display: flex;
  justify-content: flex-end;
}

.alignCenterText {
  text-align: center;
}
