@use '../../style.scss' as global;

.container {
  width: 100%;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  position: sticky;
  padding-top: global.$spacing-xl;
  padding-bottom: global.$spacing-large;
}

.menuButton {
  color: global.$color-dark-blue-new !important;
  background-color: global.$color-light-blue !important;
  margin: 0 global.$spacing-medium auto global.$spacing-xl !important;
}

.header {
  padding: 0 global.$spacing-xl;
  display: flex;
  flex-direction: column;
  gap: global.$spacing-large;
  align-items: flex-start;

  @media (max-width: 768px) {
    padding-left: global.$spacing-medium;
  }
}

.headerItem {
  align-self: stretch;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  padding: 0 global.$spacing-xl global.$spacing-xl;
}
