@use '../../style.scss' as global;

.dataGrid {
  height: calc(100vh - 230px);
  width: 100%;
}

.instructions {
  margin: 30px 0 30px 0;
}
