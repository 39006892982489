@use '../../style.scss' as global;

:global(.MuiDialog-paperScrollPaper) {
  max-width: 400px !important;
  border-radius: 12px !important;
}

.container {
  position: relative;
  padding: global.$spacing-large;
}

.contentWrapper {
  padding-top: global.$spacing-medium;
  display: flex;
  flex-direction: column;
}

.noIconContentWrapper {
  padding-top: global.$spacing-xl;
}

.iconWrapper {
  background-color: global.$color-red-50;
  width: 56px;
  height: 56px;
}

.closeIconButton {
  position: absolute !important;
  right: global.$spacing-medium;
  top: global.$spacing-medium;
  border-width: 0 !important;
  width: 32px;
  height: 32px;
}

.buttonsWrapper {
  margin-top: global.$spacing-xl;
  display: flex;
  justify-content: space-between;
  gap: global.$spacing-small;
}

.singleButton {
  justify-content: flex-end;
}

.button {
  width: 170px;
}

.confirmButton {
  background-color: global.$color-red-600 !important;
  color: global.$color-white;
}

.confirmButtonText {
  color: global.$color-white;
}

.subtitle {
  color: global.$color-gray
}
