@use '../../PatientDashboardCommon.scss';
@use '../../../../style.scss' as global;

.topContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: global.$spacing-xl;
}

.leftContainer {
  min-width: 250px;
  max-width: 400px;
}

.rightContainer {
  min-width: 500px;
  flex: 1;
}

.insightsTextContainer {
  margin-bottom: global.$spacing-medium;
}

.metricsContainer {
  margin-top: global.$spacing-xl;
}

.tab {
  font-size: 14px !important;
  line-height: 20px !important;
  color: global.$color-gray !important;
  text-transform: none !important;
}

.tab.active {
  color: global.$color-gray-blue !important;
  background-color: global.$color-gray-50;
}

:global(div.google-visualization-tooltip) {
  border-width: 0 !important;
  border-radius: 16px !important;
  font-family: 'Outfit';
  box-shadow: 0 12px 40px 0 #0000001F !important;
}
