@use '../../../../style.scss' as global;


.container {
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid #E9EDF0;
  padding: 24px;
  gap: global.$spacing-medium;
  background: white;
  flex-direction: column;
  margin-bottom: global.$spacing-xs;
  margin-top: global.$spacing-xs;
}

.darkBlueText {
  color: global.$color-dark-blue-new;
}

.lightGrayText {
  color: global.$color-gray-700;
}

.senderHeader {
  display: inline-flex;
  justify-content: start;
}

.senderName {
  margin: auto global.$spacing-xs;
}

.lastMessageTime {
  margin: auto 0px;
}

.taskContainer {
  border-left: 2px solid global.$color-dark-blue-500;
  display: inline-flex;
  padding: global.$spacing-small global.$spacing-medium;
}
.taskTimeStatusText {
  width: 100%;
}
.viewTaskButton {
  height: 36px;
  margin-bottom: global.$spacing-xs !important;
  margin-top: auto !important;
  margin-left: global.$spacing-xs !important;
}

.noBorderStatusInline {
  background: none !important;
  border: none !important;
  display: inline-block !important;
}

.replyInputContainer {
  display: inline-flex;
}

.sendButton {
  margin: 4px !important;
}

$avatar-size: 32px;

.avatar {
    margin: auto 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $avatar-size;
    height: $avatar-size;
    border-radius: calc(#{$avatar-size} / 2);
    border: 0.5px solid global.$color-border-gray;
    background-color: global.$color-dark-blue-100;
    margin-right: global.$spacing-small;
}

.initials {
    color: global.$color-gray-blue;
}

.responseHeader {
  display: inline-flex;
}

.responseMessageContainer {
  background-color: global.$color-dark-blue-50;
  border: 1px solid global.$color-dark-blue-100;
  border-radius: 16px;
  border-top-left-radius: 0;
  padding: 10px 14px;
  margin-left: 18px;
}

.responseDetailsButton {
  margin-left: auto !important;
}

.responseFooter {
  display: inline-flex;
  margin-left: 18px;
  margin-top: -12px;
}
.responseFooter>* {
  margin: auto 0;
}