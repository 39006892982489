@use '../../style.scss' as global;

:root {
  --background-color: white;
  --border-color: lightgrey;
  --hover-color: #fafafa;
}

table {
  border-collapse: collapse;
  border-radius: 3px;
  font-family: 'Outfit';
  font-size: 10pt;
  text-align: left;
  width: fit-content;
}

tr {
  border: 1px solid var(--border-color);
  cursor: pointer;

  &:hover {
    background-color: var(--hover-color);
  }
}

td {
  padding: global.$spacing-medium global.$spacing-xs;
}

th {
  background-color: var(--background-color);
  color: grey;
  padding: global.$spacing-small global.$spacing-xs;
  text-align: center;
  top: 0;
  &:hover {
    background-color: var(--hover-color);
    color: black;
  }
}

.table {
  margin-top: 20px;
  border: none;

  & th {
    cursor: default;

    &:hover {
      background-color: global.$color-white;
    }
  }

  & thead {
    border: none;
  }

  & tr {
    border: none;
    border-bottom: 1px solid global.$color-gray-100;
  }

  & .wrap {
    max-width: 250px;

    @media (max-width: 1200px) {
      max-width: 200px;
    }
  }

  & .textWrap {
    text-wrap: wrap;
    word-wrap: break-word
  }
}
