@use '../../../../style.scss' as global;

.patientsTable {
  min-width: 700px;
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
  border: 0;
  position: relative;
}

thead {
  position: sticky;
  top: 0;
  z-index:1;
}

.tableRow {
  box-sizing: border-box;
  background-color: global.$color-white;
  border: none;
  border-bottom: 1px solid global.$color-gray-100;

  &:hover {
    background-color: global.$color-gray-50;
  }
}

.nameColumn {
  width: calc(220px - 2 * #{global.$spacing-xs});
}

.taskMessageColumn {
  width: calc(20vw - 2 * #{global.$spacing-xs});

  @media (min-width: global.$medium-display-resolution) {
    width: calc(450px - 2 * #{global.$spacing-xs});
  }

  @media (min-width: global.$large-display-resolution) {
    width: calc(610px - 2 * #{global.$spacing-xs});
  }
}

.patientMessageColumn {
  width: calc(15vw - 2 * #{global.$spacing-xs});

  @media (min-width: global.$medium-display-resolution) {
    width: calc(330px - 2 * #{global.$spacing-xs});
  }

  @media (min-width: global.$large-display-resolution) {
    width: calc(450px - 2 * #{global.$spacing-xs});
  }
}

.dateColumn {
  width: calc(160px - 2 * #{global.$spacing-xs});
}

.statusColumn {
  width: calc(100px - 2 * #{global.$spacing-xs});
}

.detailsColumn {
  width: calc(50px - 2 * #{global.$spacing-xs});
}
