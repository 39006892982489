@use '../../style.scss' as global;

.button {
  box-sizing: border-box;
  padding: 9px 13px !important;
  border: 1px solid global.$color-gray-200 !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: global.$color-dark-blue-new !important;
  text-transform: none !important;
}

.button.active {
   font-weight: 800 !important;
}

.menuItem {
  height: 24px;
  display: flex;
  align-items: center;
}

.menuItemText {
  margin-left: global.$spacing-xs;
}

:global(.MuiMenu-List) {
  padding: 0 !important;
}

:global(.MuiMenuItem-root) {
  padding: global.$spacing-xs global.$spacing-medium !important;
}

:global(li.MuiButtonBase-root) {
  //prevent aligning options in 1 line after refreshing a page - the periodical bug
  display: flex !important;
  justify-content: flex-start !important;
}

