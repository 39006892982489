@use '../../style.scss' as global;

.filterContainer {
  margin-top: 15px;
  margin-bottom: 15px;
}

.buttonItem {
  margin: 5px !important;
  width: 230px;
}

.selectItem {
  width: 380px;
}

.dataGridUser,
.dataGridFlag {
  width: 100%;
}

.dataGridUser {
  height: calc(100vh - 175px);
}

.dataGridFlag {
  height: calc(100vh - 330px);
}

.paneInput {
  width: 650px;
  font-size: 14px;
}

.paneArea {
  margin-top: 10px;
}

.pane {
  width: 650px;
}

.buttonPane {
  margin: 10px 0 10px 240px;
  font-size: 16px;
  height: 25px;
}

.taskBullet {
  display: block !important;
  padding: 0 !important;
  width: auto !important;
}

.activeTaskChip {
  margin-right: 5px;
}
