@use '../../style.scss' as global;

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 524px;
  margin-bottom: 32px;
}

.subContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formRow {
  display: flex;
  gap: 16px;
}

.labelSelect {
  font-family: 'Outfit';
  color: global.$color-gray-700;
  font-weight: 400;
  margin-bottom: 2px;
  padding-left: 4px;
  font-size: 14px;
  line-height: 20px;
}

.memberItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 16px;
}

.button {
  align-self: flex-end !important;
}

.apiError {
  margin-top: 20px !important;
  color: global.$color-red !important;
}

.successMessage {
  margin-top: 20px !important;
  color: global.$color-dark-blue !important;
}
