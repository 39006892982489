@use '../../../style.scss' as global;

.cardHeader {
  padding: 0 0 5px 22px !important;
  margin-bottom: 0;
}

.avatar {
  height: 20px !important;
  width: 20px !important;
  font-size: 10px !important;
  background-color: #ecf8fd !important;
  color: global.$default-primary-color !important;
  border: 1px solid global.$default-primary-color;
}

.createdStamp {
  color: gray !important;
}
