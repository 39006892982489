@use '../../style.scss' as global;

.inboxLayoutContainer { 
    width: 100%;
    height: 100%;
    display: inline-flex;
}

.conversationsAreaContainer {
    width: 396px;
    border-left: 1px solid global.$color-border-gray;
    border-right: 1px solid global.$color-border-gray;
    padding: global.$spacing-medium 0px;

}
.conversationFeedAreaContainer { 
    width: calc(100% - 396px);
}