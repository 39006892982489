@use '../../style.scss' as global;

nav > ol {
  & > li, & > li span {
    color: global.$color-gray !important;
  }

  & > li:last-child span {
    color: global.$color-dark-blue-new !important;
  }
}
