@use '../../style.scss' as global;
@use '../../assets/styles/Table.scss';

.tableContainer {
  width: 100%;
  overflow-x: scroll;
  overflow-y: visible;
}

.table {

  & tr:hover {
    background-color: transparent;
    cursor: default;
  }

  & td {
    padding: global.$spacing-small;
    text-align: center;
    border-right: 1px solid global.$color-gray-100;

    &:last-child {
      border-right: none;
    }
  }

  & th {
    border-right: 1px solid global.$color-gray-100;

    &:last-child {
      border-right: none;
    }
  }

  & th:first-child {
    @media (max-width: 1200px) {
      max-width: 220px;
    }
  }

  & tbody th {
    text-align: left;
  }
}
