@use '../../../../style.scss' as global;

.drawerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 560px;
  padding: global.$spacing-medium global.$spacing-xl global.$spacing-xl;
}

.drawerButtonHeader {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: global.$spacing-xs;
}

.redText {
  color: global.$color-red-600 !important;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: global.$spacing-2xl;
  margin-top: global.$spacing-xs;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.topMargin {
  margin-top: global.$spacing-large;
}

.date {
  margin-top: global.$spacing-xs;
}

.dateGray {
  color: global.$color-gray;
}
