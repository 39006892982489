@use '../../../../style.scss' as global;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: global.$spacing-medium;
}

.grayText {
  color: global.$color-gray;
}

.decoration {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lineWrapper {
  display: flex;
  flex: 1;
  padding: global.$spacing-3xs 0;
}

.circle {
  box-sizing: border-box;
  margin: 2px;
  width: 16px;
  height: 16px;
  border: 1.5px solid global.$color-gray-400;
  border-radius: 8px;
}

.line {
  box-sizing: border-box;
  width: 1px;
  background-color: global.$color-gray-400;
}

.logContainer {
  display: flex;
  flex-direction: row;
  gap: global.$spacing-small;
}

.log{
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: global.$spacing-xl;
}

.lastLog {
  padding-bottom: 0;
}

.messageWrapper {
  box-sizing: border-box;
  width: 100%;
  margin-top: global.$spacing-small;
  padding: global.$spacing-xs global.$spacing-small;
  background-color: global.$color-dark-blue-50;
  border: 1px solid global.$color-dark-blue-100;
  border-radius: 0 8px 8px 8px;
}
