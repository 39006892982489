@use '../../style.scss' as global;

@mixin text {
  font-family: 'Outfit';
  color: global.$color-gray-700;
  font-weight: 400;
}

@mixin icon {
  display: inline-block;
  pointer-events: none;
}

$disabled-color: global.$color-gray-400;

.container {
  position: relative;
  width: 100%;
  margin: 0 4px 0 4px; // space for shadow when "isFocused"
}

.inputContainer {
  gap: global.$spacing-xs;
  border-radius: 8px;
  border: 1px solid global.$color-gray-200;
  background: global.$color-white;
  margin: 4px 0 4px 0; // space for shadow when "isFocused"
  padding: 10px 14px;

  &.isFocused {
    border: 1px solid global.$color-gray-300;
    box-shadow: 0 0 0 4px rgba(16, 24, 40, 0.05);
  }

  &.isFocused.error {
    border: 1px solid global.$color-red-300;
    box-shadow: 0 0 0 4px rgba(240, 68, 56, 0.24);
  }

  &.isDisabled {
    background: global.$color-gray-50;
  }

  &.error {
    border: 1px solid #FDA29B;
  }
}

.inputSubContainer {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.input {
  @include text;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: global.$color-gray-950;
  flex-grow: 1;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  resize: none;
  overflow-y: auto;

  &::placeholder {
    color: global.$color-gray-700;
  }

  &:disabled {
    color: $disabled-color;
  }
}

.label {
  @include text;
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 20px;

  .isDisabled & {
    color: $disabled-color;
  }
}

.error {
  @include text;
  margin-top: 2px;
  color: #D92D20;
  font-size: 14px;
  line-height: 20px;
}

.alertCircleIcon {
  @include icon;
  height: 16px;
  width: 16px;
}

.helperText {
  @include text;
  margin-top: 2px;
  font-size: 14px;
  line-height: 20px;

  .isDisabled & {
    color: $disabled-color;
  }
}

.leading {
  @include icon;
  display: flex;
  align-items: center;
  color: global.$color-gray-700;
}

.trailing {
  @include icon;
}

.sendButtonContainer {
  display: flex;
  margin-top: 4px;
}
.sendButton {
  margin-left: auto !important;
}
.maxLength {
  color: global.$color-gray-700;
  margin-top: auto;
}