@use '../../UserDetails';

.headerCell {
  background-color: white !important;
}

.selectBox {
  text-align: left;
}

.addScheduleRow {
  display: flex !important;
}
.plusIcon {
  width: 17px;
  height: 17px;
  margin-right: 10px;
}
.newScheduleLabel {
  font-size: 18px !important;
  color: black !important;
}
