@use '../../../../style.scss' as global;

@mixin text {
  font-family: 'Outfit';
  color: global.$color-gray-700;
  font-weight: 400;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: global.$spacing-medium global.$spacing-xl;
}

.drawerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 557px;
  height: 100%;
  flex-grow: 1;
}

.drawerHeader {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: global.$spacing-xs;
}

.drawerFooter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: global.$color-gray-100;
  padding-top: global.$spacing-medium;
}

.title {
  height: 38px;
  font-size: 30px;
  line-height: 38px;
  color: global.$color-dark-blue-new;
  font-family: 'Outfit';
  text-align: left;
  width: 100%;
  margin-bottom: global.$spacing-large;
  margin-top: global.$spacing-large;
  font-weight: 500;
}

.informationBox {

  background-color: global.$color-dark-blue-50;
  border: 1px solid global.$color-dark-blue-100;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 24px;

  .informationIconContainer {
    display: inline-block;
    height: 100%;
    vertical-align: top;
  }

  .informationMessage {
    font-family: 'Outfit';
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: global.$color-dark-blue-new;
    width: calc(100% - 44px);
    margin-left: 12px;
    display: inline-block;
  }
  
}

.messageContainer {
  width: 100%;
  font-family: 'Outfit';
  margin-bottom: global.$spacing-xs;

  .messageTitle {
    color: global.$color-dark-blue-new;
    font-size: 18px;
    line-height: 22px;
    height: 22px;
    margin-bottom: global.$spacing-3xs;
  }

  .messageDescription {
    color: global.$color-gray;
    font-size: 16px;
    line-height: 24px;
    height: 24px;
    letter-spacing: -2%;
    margin-bottom: 16px;
  }

  .conditionsContainer {
    width: 100%;
    display: inline-flex;
    
    .conditionHolder {
      width: 208px;
      display: inline-flex;
      margin-right: global.$spacing-xs;
    }
  }
}

.messageCntContainer {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: end;
  margin-bottom: global.$spacing-medium;
}

.grayRegularText {
  color: global.$color-gray;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
}

.error {
  @include text;
  margin-top: 2px;
  color: #D92D20;
  font-size: 14px;
  line-height: 20px;
}