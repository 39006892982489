@use '../../style.scss' as global;


.alertTitle {
  color: black;
}

.message {
  color: global.$color-gray;
}

.icon {
  width: 32px;
  height: 32px;
}

.alert {
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: global.$color-gray-200;
}