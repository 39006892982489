@use '../../PatientDashboardCommon.scss';
@use '../../../../style.scss' as global;

.errorMessage {
  margin-top: 20px !important;
  color: global.$color-red !important;
}

.successMessage {
  margin-top: 20px !important;
  color: global.$color-dark-blue !important;
}
