@use '../../../../style.scss' as global;

$avatar-size: 32px;

.tableCell {
  line-height: 1;
  padding: global.$spacing-medium global.$spacing-xs;
}

.cellBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $avatar-size;
  height:$avatar-size;
  border-radius: calc(#{$avatar-size} / 2);
  border: 0.5px solid global.$color-border-gray;
  background-color: global.$color-dark-blue-100;
  margin-right: global.$spacing-small;
}

.initials {
  color: global.$color-gray-blue;
}

.patientPresenceStatus {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.patientPresenceTime {
  margin-left: global.$spacing-medium;
}

.octagonImg {
 width: 20px;
 margin-right: global.$spacing-2xs;
}

.dotImg {
  width: 10px;
  margin-right: global.$spacing-2xs;
  height: 10px;
}

@mixin cellTextBase {
  min-width: 36px;
}

.cellText {
  @include cellTextBase;
}

.timeText {
  margin-left: 0;
  margin-right: 0;
  text-wrap: nowrap;
  color: global.$color-gray-700;
}

@mixin deviceStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 0.75px solid;
  padding: 0 global.$spacing-2xs;
}

.deviceIdStatus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: global.$spacing-xs;
}

.deviceStatusActive {
  @include deviceStatus;
  border-color: global.$color-light-green-700;
  color: global.$color-light-green-700;
  background-color: global.$color-light-green-300;
  min-width: 60px;
}

.cellTextDeviceStatusActive {
  @include cellTextBase;
  color: global.$color-light-green-700;
  text-align: center;
}

.deviceStatusOffline {
  @include deviceStatus;
  border-color: global.$color-gray-900;
  color: global.$color-gray-900;
  background-color: global.$color-gray-100;
  min-width: 60px;
}

.cellTextDeviceStatusOffline {
  @include cellTextBase;
  color: global.$color-gray-900;
  text-align: center;
}

.deviceStatusError {
  @include deviceStatus;
  border-color: global.$color-red-700;
  color: global.$color-red-700;
  background-color: global.$color-red-100;
  text-align: center;
}

.cellTextDeviceStatusError {
  @include cellTextBase;
  color: global.$color-red-700;
}
