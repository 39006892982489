@use '../../style.scss' as global;

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 600px;
  margin-bottom: 32px;
}

.patientInfoContainer, .addressContainer {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-color: global.$color-gray-200;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px;
}

.subContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.subContainerEvenDistribution {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.formRow {
  display: flex;
  gap: 16px;
}

.labelSelect {
  font-family: 'Outfit';
  color: global.$color-gray-700;
  font-weight: 400;
  margin-bottom: 2px;
  padding-left: 4px;
  font-size: 14px;
  line-height: 20px;
}

.button {
  align-self: flex-end !important;
}

.apiError {
  margin-top: 20px !important;
  color: global.$color-red !important;
}

.successMessage {
  margin-top: 20px !important;
  color: global.$color-dark-blue !important;
}

.phoneLeadingText {
  color: global.$color-gray;
}

.street {
  width: 65%;
}

.postalCode {
  width: 35%;
}

.submitButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
}

.buttonCancel {
  @extend .button;
  margin-right: 10px !important;
}
