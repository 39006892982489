@use '../../PatientDashboardCommon.scss';
@use '../../../../style.scss' as global;

.card {
  @extend .columnContainer;
  padding: global.$spacing-medium;
  border: 1px solid global.$color-gray-200;
  border-radius: 16px;
  box-sizing: border-box;
}

.cardTitleContainer {
  @extend .columnContainer;
  gap: 0;
}

.vitalContainer {
  @extend .columnContainer;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  margin-top: global.$spacing-xs;
}

.vitalValueContainer {
  @extend .columnContainer;
  align-items: center;
  justify-content: center;
  width: 40px;
}

.vitalValueText {
  margin-top: global.$spacing-xs;
  text-align: center;
}

.vitalValueUnit {
  @extend .vitalValueText;
  color: global.$color-gray;
}

.redText {
  color: global.$color-red-600;
}

.yellowText {
  color: global.$color-yellow-400;
}

.vitalSubtitleStub {
  height: 20px;
}
