@use '../../style.scss' as global;

.title {
  align-self: flex-start;
  margin-top: 24px;
}

.filterRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 32px;
}

.searchField {
  margin: 0;
}
.categoryFilter {
  margin-left: 12px;
  min-width: 187px !important;
  margin-left: 16px !important;
  margin-top: 5px !important;
  font-size: 16px !important;
}

.headerButton {
  width: 132px !important;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.drawerButtonsContainer {
  display: flex;
  gap: 8px;
  align-self: flex-end;
  margin-bottom: 16px;
  margin-top: 24px;
}

.itemList {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  margin-top: 32px !important;
}

.boxLabelBox {
  margin-left: 8px;
  margin-top: 1px;
}

.boxLabel {
  line-height: 16px !important;
}

.boxLabelDescription {
  color: #6C809A;
  margin-top: 4px;
}

.contentIcon {
  margin-right: 16px;
}

.rowItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
  cursor: pointer;
}
