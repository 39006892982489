@use '../../../../style.scss' as global;

.cardContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  border: 1px solid global.$color-gray-200;
  border-radius: 16px;
}

.card {
  @extend .cardContainer;
  padding: global.$spacing-medium;
}

.smallCard {
  @extend .cardContainer;
  justify-content: flex-start;
  padding: global.$spacing-large global.$spacing-2xl global.$spacing-medium;
}

.content {
  display: flex;
  flex-direction: row;
  gap: global.$spacing-large;
  flex-wrap: nowrap;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: global.$spacing-xs;
}

.title {
  text-wrap: nowrap;
}
