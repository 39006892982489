@use '../../style.scss' as global;


.topContainer {
  width: 400px;
  height: 100%;
  background: global.$color-white;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: global.$spacing-medium;
  box-sizing: border-box;
}

.avatar {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 45px;
  border: 0.75px solid global.$color-border-gray;
  background-color: global.$color-dark-blue-100;
  color: global.$color-gray !important;
  margin-bottom: global.$spacing-large;
}

.avatarAndText {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: global.$spacing-medium;
}

.grayText {
  color: global.$color-gray-blue;
}

.grayEmailText {
  color: global.$color-gray-700;
}

.patientDetail {
  margin-bottom: global.$spacing-large;
}

.fieldValue {
  margin-bottom: global.$spacing-medium;
}
