@use '../../../../style.scss' as global;

$left-bubble-text: #ffffff;
$right-bubble-text: #000000;

.oldChatContainer {
  border: 1px solid #d0dce2;
  margin-top: 20px;
  margin-bottom: 20px;
}

.chatContentContainer {
  height: 75vh;
  display: flex;
  flex-direction: column;
}

.chatContainerBlue {
  @extend .oldChatContainer;
  border: 1px solid global.$default-primary-color;
  background-color: #ecf8fd;
}

.listContainer {
  flex-grow: 1;
  padding-left: 20px !important;
  padding-right: 20px !important;
  overflow: scroll;
  height: auto;
}

.bubble {
  border-radius: 12px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.leftSideBubble {
  @extend .bubble;
  background-color: global.$default-primary-color;
}

.rightSideBubble {
  @extend .bubble;
  border: 1px solid global.$default-primary-color;
  float: right;
  text-align: right;
  margin-bottom: 6px;
}

.chatFullName {
  font-size: 12px !important;
  margin-bottom: 6px !important;
}

.leftChatFullName {
  @extend .chatFullName;
}

.rightChatFullName {
  @extend .chatFullName;
}

.listItemAvatarLeft {
  min-width: 40px !important;
  align-self: flex-start !important;
  margin-top: 24px !important;
}

.listItemAvatarRight {
  min-width: 40px !important;
}

.chatAvatarLeft {
  background-color: global.$default-primary-color !important;
  margin-right: 6px !important;
  margin-left: 0 !important;
}

.chatAvatarRight {
  background-color: transparent !important;
  margin-right: 0 !important;
  margin-left: 6px !important;
}

.lunaIcon {
  height: 35px;
}

.chatMessage {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-bottom: 4px !important;
}

.chatMessageRight {
  @extend .chatMessage;
  justify-content: flex-end !important;
  align-items: flex-end !important;
  text-align: right !important;
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.chatMessageText {
  font-size: 18px !important;
  max-width: 60vw;
}

.leftChatMessageText {
  @extend .chatMessageText;
  color: $left-bubble-text;
}

.rightChatMessageText {
  @extend .chatMessageText;
  color: $right-bubble-text;
}

.chatDate {
  font-size: 12px !important;
}

.leftChatDate {
  @extend .chatDate;
  color: $left-bubble-text;
}

.rightChatDate {
  @extend .chatDate;
  color: $right-bubble-text;
}

.chatMessageFooterContainer {
  flex-direction: column-reverse;
}

.chatMessageFooter {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.chatMessageFooterButtonEmoji {
  margin-right: 10px !important;
  margin-left: -10px !important;
  padding-top: 0 !important;
  align-self: flex-end !important;
}

.chatMessageFooterButtonSend {
  padding-right: 0 !important;
  padding-left: 10px !important;
  padding-top: 0 !important;
  align-self: flex-end !important;
}

.checkmarkLabel {
  margin-right: 0 !important;
  font-size: 12px !important;
}

.checkmarkLabelBlue {
  @extend .checkmarkLabel;
  color: global.$default-primary-color !important;
}

.checkmarkLabelRed {
  @extend .checkmarkLabel;
  color: #f50057 !important;
}

.agentMessageRead {
  @extend .checkmarkLabel;
  color: #000000;
  opacity: 0.5;
}
