@use '../../../../style.scss' as global;

.noRequestsText {
    margin-left: global.$spacing-medium;
    color: global.$color-gray-700
}
.conversationsHolder {
    padding: global.$spacing-medium;
    overflow-y: auto;
    height: calc(100% - 48px);
}

.conversationItem {
    display: inline-flex;
    height: 48px;
    padding: global.$spacing-medium;
    width: calc(100% - 32px);
    cursor: pointer;
    border-radius: 8px;
}
.conversationItem:hover {
    background-color: global.$color-gray-50;
}
.selectedConversation {
    background-color: global.$color-gray-50;
}


$avatar-size: 48px;

.avatar {
    margin: auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $avatar-size;
    height: $avatar-size;
    border-radius: calc(#{$avatar-size} / 2);
    border: 0.5px solid global.$color-border-gray;
    background-color: global.$color-dark-blue-100;
    margin-right: global.$spacing-small;
}

.initials {
    color: global.$color-gray-blue;
}

.conversationLabel {
    height: 100%;
    width: 178px
}

.conversationLabelText {
    width:100%; 
    display: block;
    color: global.$color-gray-700;
}

.conversationTimeText {
    width: calc(100% - 240px);
    text-align: right;
    color: global.$color-gray-700;
}

.selectedConversation .conversationLabelText.personName {
    color: global.$color-dark-blue-new;
}