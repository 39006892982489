@use '../../style.scss' as global;

.container {
  max-width: 400px;
  min-width: 200px;
  margin-bottom: 32px;
}

.button {
  background-color: global.$color-dark-blue !important;
  &:hover {
    background-color: global.hex-to-rgba(global.$color-dark-blue, 0.5) !important;
  }
}

.labelSelect {
  font-family: 'Outfit';
  color: global.$color-gray-700;
  font-weight: 400;
  margin-bottom: 2px;
  padding-left: 4px;
  font-size: 14px;
  line-height: 20px;
}
