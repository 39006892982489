@use '../../UserDetails';

.rowEditCell {
  width: 60px;
}

.rowEditCell,
.number,
.text,
.timestamp,
.select {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center !important;
}

.editTable {
  max-width: fit-content;
  white-space: nowrap;
}

.timestamp,
.number,
.select {
  width: 190px;
}

.text {
  width: 350px;
}

.editInput {
}

.editInput > input {
  padding: 0;
  text-align: center;
  height: 20px;
}
.editInput > input[type='text'] {
  width: 340px;
}

.editInput > input[type='number']::-webkit-inner-spin-button,
.editInput > input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.datePickerInput {
  padding: 0 !important;
  height: 20px;
  width: 180px !important;
}
