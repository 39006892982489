@use '../../../../style.scss' as global;


.emptyArea {
    background-color: global.$color-gray-50;
    height: 100%;
    width: 100%;
}

.centeredContent {
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}

.centeredIconCircle {
    margin: 0 auto;
}