@use '../../../../style.scss' as global;

.conversationArea {
    background-color: global.$color-gray-50;
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.chatFeedHeader {
    width: calc(100% - 64px);
    height: 48px;
    border-bottom: 1px solid global.$color-border-gray;
    background: white;
    padding: global.$spacing-medium global.$spacing-xl;
    display: inline-flex;
    justify-content: space-between;
}

.conversationLabel {
    height: 100%;
    width: calc(100% - 200px);
}

.conversationLabelText {
    width:100%; 
    display: block;
    color: global.$color-gray-700;
}

.personName {
    color: global.$color-dark-blue-new;
}

$avatar-size: 48px;

.avatar {
    margin: auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: $avatar-size;
    height: $avatar-size;
    border-radius: calc(#{$avatar-size} / 2);
    border: 0.5px solid global.$color-border-gray;
    background-color: global.$color-dark-blue-100;
    margin-right: global.$spacing-small;
}

.initials {
    color: global.$color-gray-blue;
}

.chatFeedContainer {
    width: 100%;
    max-width: 644px;
    margin: 0 auto;
    height: calc(100% - 82px);
    overflow: auto;
}