@use '../../../../style.scss' as global;

.button {
  color: global.$color-dark-blue !important;
  &:disabled {
    color: global.hex-to-rgba(global.$color-dark-blue, 0.25) !important;
  }
  &:hover {
    color: global.hex-to-rgba(global.$color-dark-blue, 0.5) !important;
    background-color: global.hex-to-rgba(global.$color-dark-blue, 0.05) !important;
  }
}
