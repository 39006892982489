@use '../../style.scss' as global;

$tile-color: #f3f7fa;

.root {
  flex-grow: 1;
  margin: 8px;
}

.center,
.gridHeaderCenter,
.gridHeaderChip,
.gridItem,
.gridItemInnerIcon,
.sleepSchedule {
  text-align: center;
}

.gridBody {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.gridHeaderIcon {
  padding-top: 7px;
}

.gridHeaderCenterTitle {
  font-weight: bold;
}

.gridItem {
  border-style: solid;
  border-width: 1px;
  height: 40px;
}

.gridItemInner {
  text-align: left;
  height: 100%;
  padding-left: 20px;
}

.gridItemInnerIcon {
  height: 100%;
  padding-top: 7px;
}

.headerDiv {
  float: left;
}

.button {
  height: 37px;
  width: 329px;
  @media (max-width: 960px) {
    width: 100%;
  }
}

.card {
  margin-top: 20px;
  line-height: 1.5;
  background-color: $tile-color !important;
}

.sleepSchedule {
  margin-top: 20px !important;
}

.gridChipButtons {
  padding-top: 10px;
  padding-bottom: 10px;
}

.chip {
  width: 180px;
  padding: 0 !important;
}

.titleText {
  padding-bottom: 28px;
  width: auto;
}

.titleAvatar {
  margin-left: 10px;
  height: 100%;
}

.pushNotificationHeaderCell {
  border: 1px solid black !important;
  font-weight: bold !important;
}

.selectButtonGridItem {
  padding-right: 10px !important;
  padding-bottom: 10px !important;
}

.headerCell,
.rowCell,
.rowEditCell,
.surveyHeaderCell {
  border: 1px solid black !important;
}

.headerCell,
.surveyHeaderCell {
  text-decoration: underline;
}

.surveyHeaderCell {
  background-color: white !important;
}

.surveyScoreTable {
  margin-top: 10px;
}

.assignFlagHeader {
  padding-left: 0 !important;
  padding-top: 8px !important;
  padding-bottom: 0 !important;
}

.assignFlagButton {
  padding: 0 !important;
}
