@use '../../style.scss' as global;

.tile,
.subTile {
  min-height: 56px;
}

.tile {
  margin-bottom: 20px;
  cursor: pointer;
}

.subTile {
  margin-bottom: 10px;
}

.tileNumber,
.subtileNumber,
.flaggedUser {
  margin-left: 5px !important;
}

.tileNumber,
.subtileNumber {
  background-color: #1b4a6c !important;
}

.subtileNumber {
  width: 22px !important;
  height: 22px !important;
  font-size: 8px !important;
  margin-right: 10px !important;
}

.tileTitle {
  margin-left: 10px !important;
}

.subTitleBox {
  display: flex !important;
  align-items: flex-start !important;
  padding-bottom: 5px !important;
}

.subTitleItemBox {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
}
