@use '../../style.scss' as global;

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  padding: 2px 6px;
  background: global.$color-gray-50;
  border: 1px solid global.$color-gray-100;
  border-radius: 100px;
  width: fit-content;
  flex-wrap: nowrap;
}

.text {
  white-space: nowrap;
  color: global.$color-gray-900;
}
