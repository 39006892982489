@use '../../style.scss' as global;

.imgContainer {
  padding: global.$spacing-xs;
  border-radius: 8px;
  margin-right: global.$spacing-small;
}

.noBg {
  padding: 0;
  background-color: transparent;
}

.redBg {
  background-color: global.$color-red-100;
}

.yellowBg {
  background-color: global.$color-yellow-50;
}

.iconTextContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.valueText {
  text-wrap: nowrap;
  color: global.$color-dark-blue-new;
}

.line2Text {
  text-wrap: nowrap;
  color: global.$color-gray-700;
}

.textContainer {
  margin-left: global.$spacing-xl;
}
