@use '../../DualPane.scss';

.imageBox {
  max-height: min(80vh, 2928px);
  max-width: min(80%, 3024px);
}

.helpFooterLeft {
  font-size: 18px !important;
}

.helpFooterRight,
.helpFooterLeft {
  margin-top: 10px !important;
}

.contactUs {
  color: white;
}
