@use '../../../../style.scss' as global;

.awakeText {
  color: global.$color-dark-blue-200;
}

.remText {
  color: global.$color-dark-blue-300;
}

.lightText{
  color: global.$color-dark-blue-500;
}

.deepText {
  color: global.$color-dark-blue-700;
}

.motionText {
  color: global.$color-gray;
}

.valueText {
  color: global.$color-gray-500;
}
