@use '../../../style.scss' as global;

$size: 80px;

.container {
  box-sizing: content-box;
  width: $size;
  height: $size;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(#{$size} / 2);
  background-color: global.$color-dark-blue-100;
}
