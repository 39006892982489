.popup {
  background-color: #f4f4f4;
  border: solid #333 1px;
  border-radius: 5px;
  box-shadow: 0 0 5px #555;
  cursor: auto;
  margin: auto;
  position: absolute;
  z-index: 20;
}

.triangle {
  background-color: #f4f4f4;
  border: solid #333 1px;
  border-right: 0;
  border-bottom: 0;
  cursor: auto;
  left: 0;
  margin: auto;
  overflow: hidden;
  position: absolute;
  right: 0;
}
