@use '../../style.scss' as global;

.defaultGrayText {
  color: global.$color-gray;
}

.gray400Text {
  color: global.$color-gray-400;
}

.blue500Text {
  color: global.$color-dark-blue-500;
}

.blue600Text {
  color: global.$color-dark-blue-600;
}

.blue700Text {
  color: global.$color-dark-blue-700;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
}
