@use '../../style.scss' as global;

.container {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid;
  padding: 1px global.$spacing-xs;
}

.waiting {
  border-color: global.$color-dark-blue-200;
  background-color: global.$color-light-blue-50;

  & > span {
    color: global.$color-dark-blue-500 !important;
  }
}

.inProgress {
  border-color: global.$color-orange-100;
  background-color: global.$color-orange-50;

  & > span {
    color: global.$color-orange-600 !important;
  }
}

.success {
  border-color: global.$color-light-green-300;
  background-color: global.$color-light-green-50;

  & > span {
    color: global.$color-light-green-700 !important;
  }
}

.canceled {
  border-color: global.$color-gray-100;
  background-color: global.$color-gray-50;

  & > span {
    color: global.$color-gray-900 !important;
  }
}

.error {
  border-color: global.$color-red-100;
  background-color: global.$color-red-50;

  & > span {
    color: global.$color-red-600 !important;
  }
}

.delivered {
  border-color: global.$color-purple-100;
  background-color: global.$color-purple-50;

  & > span {
    color: global.$color-purple-600 !important;
  }
}

.expired {
  border-color: global.$color-pink-200;
  background-color: global.$color-pink-50;

  & > span {
    color: global.$color-pink-600 !important;
  }
}
