@use '../../style.scss' as global;

.button {
  margin: 0 5px 0 0 !important;
}

.filterContainer {
  margin-top: 15px;
  margin-bottom: 15px;
}

.dataGrid {
  height: calc(100vh - 165px);
  width: 100%;
}

.filterItem {
  width: 490px;
}

.firmwareItem {
  width: 260px;
  margin: 0 5px 0 0 !important;
}

.infoIcon {
  color: black;
  cursor: help;
  display: inline;
  margin-left: 4px;
  position: relative;
}

.select {
  display: inline;
  margin: 0 5px 5px 0;
}

.buttonsBar {
  margin-top: 10px;
}
