@use '../../../../style.scss' as global;

$avatar-size: 32px;

.tableCell {
  box-sizing: border-box;
  line-height: 1;
  padding: global.$spacing-medium global.$spacing-xs;
}

.cellBox {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $avatar-size;
  height: $avatar-size;
  border-radius: calc(#{$avatar-size} / 2);
  border: 0.5px solid global.$color-border-gray;
  background-color: global.$color-dark-blue-100;
  margin-right: global.$spacing-small;
}

.initials {
  color: global.$color-gray-blue;
}

.cellText {
  min-width: 36px;
}

.cellIconText {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: global.$spacing-small;
}

.noWrapText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iconBox {
  width: 20px;
  height: 20px;
}
