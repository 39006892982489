@use '../../../style.scss' as global;

.container {
  display: flex;
  justify-content: space-between;
  padding: global.$spacing-medium 0 global.$spacing-xl 0;
  margin-inline: 16px;
  border-top: 1px solid;
  border-color: global.$color-gray-100;
}

.subContainer {
  display: flex;
  width: 100%;
  overflow-wrap: break-word;
  word-break: break-all;
  align-items: center;
  gap: 12px;
  font-family: 'Outfit';
  font-size: 16px;
  font-weight: 400;
  color: global.$color-dark-blue-new;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 0.75px solid global.$color-border-gray;
  background-color: global.$color-dark-blue-100;
  color: global.$color-gray-blue;
}
