@use '../../style.scss' as global;
@use '../../assets/styles/Table.scss';

.orgHeader {
  width: 300px;
}

.labelSelect {
  color: global.$color-gray-700;
  font-weight: 400;
  margin-bottom: 2px;
  padding-left: global.$spacing-2xs;
}

.pillContainer {
  position: relative;
  display: inline-block;
  margin-right: global.$spacing-xs;
  margin-bottom: global.$spacing-xs;

  &.last {
    padding-right: global.$spacing-xl;
  }
}

.pill {
  flex: 1;
  padding: global.$spacing-2xs global.$spacing-xl;
  border-radius: 10px;
  background-color: global.$color-gray-200;
  color: global.$color-gray-700;
  cursor: default;
  position: relative;

  &:hover {
    .pillMinus {
      display: block;
    }
  }
}

.pillMinus {
  position: absolute;
  right: global.$spacing-2xs;
  top: global.$spacing-2xs;
  padding: 0 !important;
  background-color: global.$color-gray-200;
  border: none;
  cursor: pointer;
  display: none;
}

.pillAddAbsolute {
  position: absolute;
  top: global.$spacing-2xs;
  right: global.$spacing-2xs;
}

.pillAdd {
  padding: 0 !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

