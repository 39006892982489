@use '../../style.scss' as global;

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: global.$spacing-xl;
  max-width: 750px;
  margin-bottom: global.$spacing-xl;
}

.subContainer {
  display: flex;
  flex-direction: column;
  gap: global.$spacing-medium;
}

.formRow {
  display: flex;
  gap: global.$spacing-medium;

  @media (max-width: 500px) {
    flex-direction: column;
  }
}

.labelSelect {
  font-family: 'Outfit';
  color: global.$color-gray-700;
  font-weight: 400;
  margin-bottom: 2px;
  padding-left: global.$spacing-2xs;
  font-size: 14px;
  line-height: 20px;
}

.button {
  align-self: flex-end !important;
}

.apiError {
  margin-top: global.$spacing-medium !important;
  color: global.$color-red !important;
}

.successMessage {
  margin-top: global.$spacing-medium !important;
  color: global.$color-dark-blue !important;
}
.imageTextField {
  width: 700px;
}

.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: global.$color-white;
  border-radius: 5px;
  padding: global.$spacing-xl;
}
.modalForm {
  display: flex;
  flex-direction: column;
  gap: global.$spacing-xl;
  width: 700px;
}
.modalHeader {
  margin-top: global.$spacing-small;
  margin-bottom: global.$spacing-medium;
}
