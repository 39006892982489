@use '../../../../style.scss' as global;

.patientsTable {
  min-width: 700px;
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
  border: 0;
  position: relative;
}

thead {
  position: sticky;
  top: 0;
}

.tableRow {
  background-color: global.$color-white;
  border: none;
  border-bottom: 1px solid global.$color-gray-100;

  &:hover {
    background-color: global.$color-gray-50;
  }
}
