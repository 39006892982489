@use '../../style.scss' as global;

$nav-height: 72px;

.topHeaderContainer {
  height: $nav-height;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: global.$spacing-medium global.$spacing-3xl;
  border-bottom: 1px solid global.$color-gray-100;
}

.headerContainer {
  @extend .adaptiveContainer;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.logoContainer {
  flex: 1;
}

.logoImage {
  height: 100%;
}

.groupContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: global.$spacing-large;
  margin: 0 global.$spacing-large;
}

.rightMenuContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: global.$spacing-medium;
}

.tab {
  cursor: pointer;
  border: none;
  margin: 0;
  background-color: transparent;
  font-family: 'Outfit';
  font-size: 16px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  color: global.$color-gray !important;
  padding: 0 0 0 0 !important;
  text-transform: none !important;
}

.tab.active {
   color: global.$color-gray-blue !important;
   font-weight: 800 !important;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 0.75px solid global.$color-border-gray;
  background-color: global.$color-dark-blue-100;
  white-space: nowrap;
  cursor: pointer;
}

.smallAvatar {
  @extend .avatar;
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.personInitials {
  color: global.$color-gray-blue;
}

.bellContainer {
  padding: global.$spacing-xs;
}

.menuItemText {
  margin-left: global.$spacing-xs;
}

.label {
  margin-left: global.$spacing-xs;
}

.dashboardTab {
  display: flex;
}
