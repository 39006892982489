@use '../../../../style.scss' as global;

$avatar-size: 18px;

.avatar {
  font-size: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: $avatar-size;
  height:$avatar-size;
  border-radius: calc(#{$avatar-size} / 2);
  border: 0.5px solid global.$color-border-gray;
  background-color: global.$color-dark-blue-100;
  color: global.$color-gray-blue;
  margin-right: global.$spacing-small;
}


.patientSelectPlaceholderText {
  font-size: 14px;
  line-height: 32px;
  font-family: 'Outfit';
}
