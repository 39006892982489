$default-primary-color: #007eb5;

$color-dark-blue: #284660;
$color-light-blue: #E0EEF5;
$color-green: #55dd55;
$color-grey: #E6E6E6;
$color-yellow: #ffdd44;
$color-orange: #ff8844;
$color-red: #ff4444;
$color-red-2: #FF0000;
$color-white: #ffffff;
$color-black: #000000;
// new colors
$neutral-color-gray-300: #F1F1F1;
$neutral-color-gray-700: #C9C9CC;
$color-border-gray: #00000014;
$color-gray: #6C809A;
$color-gray-50: #F5F7F9;
$color-gray-100: #E9EDF0;
$color-gray-200: #D7E0E6;
$color-gray-300: #BCCAD4;
$color-gray-400: #9CAFBE;
$color-gray-500: #8498AD;
$color-gray-700: #66768F;
$color-gray-900: #485160;
$color-gray-950: #2F343C;
$color-gray-blue: #194373;
$color-dark-blue-new: #0C2545;
$color-light-blue-50: #F0F9FF;
$color-dark-blue-50: #F2F7FD;
$color-dark-blue-100: #E4EDFA;
$color-dark-blue-200: #C4DAF3;
$color-dark-blue-300: #8FBCEA;
$color-dark-blue-500: #2D7CCA;
$color-dark-blue-600: #1E61AB;
$color-dark-blue-700: #1A4D8A;
$color-light-green-50: #ECFDF3;
$color-light-green-300: #ABEFC6;
$color-light-green-700: #00835E;
$color-orange-50: #FEF6EE;
$color-orange-100: #FDEBD7;
$color-orange-600: #E04F16;
$color-pink-50: #FDF2FA;
$color-pink-100: #FCE7F7;
$color-pink-200: #FCCEEE;
$color-pink-600: #C11574;
$color-red-50: #FEF3F2;
$color-red-100: #FEE4E2;
$color-red-300: #fdaaa4;
$color-red-400: #f97970;
$color-red-500: #F04438;
$color-red-600: #DE3024;
$color-red-700: #BB241A;
$color-yellow-50: #FEFBE8;
$color-yellow-400: #FAC315;
$color-yellow-600: #CA8204;
$color-purple-50: #F4F3FF;
$color-purple-100: #D9D6FE;
$color-purple-600: #5925DC;

$spacing-3xs: 4px;
$spacing-2xs: 6px;
$spacing-xs: 8px;
$spacing-small: 12px;
$spacing-medium: 16px;
$spacing-large: 24px;
$spacing-xl: 32px;
$spacing-2xl: 40px;
$spacing-3xl: 60px;

@function hex-to-rgba($color, $alpha: 1) {
  @return rgba($color, $alpha);
}

$medium-display-resolution: calc(1320px + 2 * #{$spacing-3xl});
$large-display-resolution: calc(1600px + 2 * #{$spacing-3xl});

.root {
  flex-grow: 1;
  margin: $spacing-xs;
}

.adaptiveContainer {
  box-sizing: content-box;

  @media (min-width: $medium-display-resolution) {
    max-width: 1320px;
  }

  @media (min-width: $large-display-resolution) {
    max-width: 1600px;
  }
}

#content {
  min-height: 100%;
}

.header,
.tile,
.subTile,
.quizOption,
.pageDetailTop {
  display: flex;
  align-items: center;
  width: 100%;
}

.header,
.tile,
.subTile,
.pageDetailTop {
  justify-content: center;
}

.tile,
.subTile,
.deviceContentInput,
.quizOptionInput {
  background-color: #e8f1f8 !important;
}

.headerChipBar {
  display: flex;
  margin-bottom: 20px;
}

.tileTitle,
.headerTitleContainer {
  margin-right: auto !important;
}

.headerTitleContainer {
  margin-top: 20px;
  margin-bottom: $spacing-small;
}

.tileIcon,
.subTileIcon {
  background-color: white !important;
}

.tileIcon,
.chipIcon,
.autoAdjustChipIcon,
.subTileIcon,
.featureChip,
.subTypeChip,
.deviceContentBox {
  margin-right: $spacing-2xs !important;
}

.chipIcon,
.subTypeChip {
  min-width: 120px;
}

.tileIcon:hover,
.subTileIcon:hover {
  background-color: gray !important;
}

.tileIconBar,
.headerIconBar,
.featureSelect {
  margin-left: auto !important;
}

.subTypeChip {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.deviceContentInput,
.quizOptionInput {
  width: 100%;
  margin-bottom: 10px !important;
}

.sliderDialog {
  width: 90% !important;
  height: 100% !important;
  margin-left: auto !important;
}

.underline {
  border-bottom: 1px solid #d0dce2 !important;
}
