@use '../../style.scss' as global;

.text {
  color: global.$color-dark-blue-new;
  font-family: 'Outfit';
  margin-bottom: 0;
  margin-top: 0;
  letter-spacing: -0.02em;
}

.h1 {
  font-size: 72px;
  line-height: 90px;
}

.h2 {
  font-size: 60px;
  line-height: 72px;
}

.h3 {
  font-size: 48px;
  line-height: 60px;
}

.h4 {
  font-size: 36px;
  line-height: 44px;
}

.h5 {
  font-size: 30px;
  line-height: 38px;
}

.text2Xl {
  font-size: 24px;
  line-height: 32px;
}

.textXl {
  font-size: 20px;
  line-height: 30px;
}

.textLg {
  font-size: 18px;
  line-height: 28px;
}

.textNarrowLg {
  font-size: 18px;
  line-height: 22px;
}

.textMd {
  font-size: 16px;
  line-height: 24px;
}

.textSm {
  font-size: 14px;
  line-height: 20px;
}

.textXs {
  font-size: 12px;
  line-height: 18px;
}

.weight400 {
  font-weight: 400;
}

.weight500 {
  font-weight: 500;
}

.weight600 {
  font-weight: 600;
}

.weight700 {
  font-weight: 700;
}
