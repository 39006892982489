@use '../../style.scss' as global;

.topContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: 0 global.$spacing-3xl;
  height: calc(100% - 72px);
}

.header {
  @extend .adaptiveContainer;
  display: flex;
  flex-direction: row;
  gap: global.$spacing-large;
  align-items: flex-start;
  justify-content: space-between;
  position: sticky;
  padding: global.$spacing-xl 0 global.$spacing-large;
  box-sizing: border-box;
}

.content {
  @extend .adaptiveContainer;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 0 0 global.$spacing-xl;
  overflow-x: hidden;
}

.centerAligned {
  align-items: center;
}
