@use '../../style.scss' as global;

$areaHeight: 400px;

.container {
  box-sizing: border-box;
  height: $areaHeight;
  min-width: 600px;
  margin-top: global.$spacing-large;
  padding: global.$spacing-3xl;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: global.$spacing-xl;
  background-color: global.$color-gray-50;
  border-radius: 16px;
  border: 1px solid global.$color-gray-100;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  max-width: 512px;
}

.description {
  color: global.$color-gray;
  margin-top: global.$spacing-xs;
}
