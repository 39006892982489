@use '../../../../style.scss' as global;

$avatar-size: 56px;

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  border: 1px solid global.$color-gray-100;
  padding: global.$spacing-large;
  gap: global.$spacing-medium;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: $avatar-size;
  height:$avatar-size;
  border-radius: calc(#{$avatar-size} / 2);
  border: 0.75px solid global.$color-border-gray;
  background-color: global.$color-dark-blue-100;
}

.initials {
  color: global.$color-gray-blue;
}

.personalInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.email {
  color: global.$color-gray-700;
}
