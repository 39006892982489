@use '../../style.scss' as global;

.headerButton {
  width: 132px !important;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.title {
  align-self: flex-start;
  margin-top: 24px;
}
.filterRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 32px;
}

.searchField {
  margin: 0;
}
.categoryFilter {
  min-width: 187px !important;
  margin-left: 16px !important;
  margin-top: 5px !important;
  font-size: 16px !important;
}

.drawerButtonsContainer {
  display: flex;
  gap: 8px;
  align-self: flex-end;
  margin-bottom: 16px;
  margin-top: 24px;
}

.formControlLabel {
  margin-top: 16px;
}

.itemList {
  flex-direction: column;
  display: flex;
  margin-top: 32px;
  flex-grow: 1;
  overflow-y: auto;
}
.boxLabelBox {
  margin-left: 8px;
  margin-top: 1px;
}

.boxLabel {
  line-height: 16px !important;
}

.boxLabelDescription {
  color: global.$color-gray;
  margin-top: 4px;
}
.checkbox {
  padding: 0 !important;
}

.rowItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.contentIcon {
  margin-right: 16px;
}


.divider {
  width: 100%;
  position: absolute;
  height: 1px;
  background-color: #E9EDF0;
  bottom: 74px;
  margin-left: -32px;
}
